import * as moment from 'moment';
import 'moment-timezone';

export const dateTimeMixin = {
  filters: {
    dateTime_fromNow: function (date) {
      if (moment(date).isValid()) {
        return moment(date).fromNow();
      }
      return null;
    },
    dateTime_fromNowWithoutFuture (date) {
      if (Math.abs(moment().diff(date)) < 10000) { 
        return 'a few seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 20000) { 
        return '10 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 30000) { 
        return '20 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 40000) { 
        return '30 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 50000) { 
        return '40 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 60000) { 
        return '50 seconds ago';
      }
      return moment(date).fromNow();
    },
    dateTime_dateTime: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
      return null;
    },
    dateTime_dateTimeSeconds: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
      }
      return null;
    },
    dateTime_date: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY');
      }
      return null;
    }
  },
  methods: {
    dateTime_dateTime (date) {
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
      return null;
    },
    unix_dateTime_dateTime (date) {
      if (moment.unix(date).isValid()) {
        return moment.unix(date).format('DD.MM.YYYY HH:mm');
      }
      return null;
    },
    dateTime_dateTimeAlt (date) {
      if (moment(date).isValid()) {
        return moment(date).format('MM.DD.YYYY HH:mm');
      }
      return null;
    },
    dateTime_dateTimeSeconds (date) {
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
      }
      return null;
    },
    dateTime_dateTimeSecondsAlt (date) {
      if (moment(date).isValid()) {
        return moment(date).format('MM.DD.YYYY HH:mm:ss');
      }
      return null;
    },
    dateTime_fromNow (date) {
      if (moment(date).isValid()) {
        return moment(date).fromNow();
      }
      return null;
    },
    dateTime_fromNowWithoutFuture (date) {
      if (Math.abs(moment().diff(date)) < 10000) { 
        return 'a few seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 20000) { 
        return '10 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 30000) { 
        return '20 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 40000) { 
        return '30 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 50000) { 
        return '40 seconds ago';
      }
      else if (Math.abs(moment().diff(date)) < 60000) { 
        return '50 seconds ago';
      }
      return moment(date).fromNow();
    },
    dateTime_date: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY');
      }
      return null;
    },
    dateTime_iso: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('YYYY-MM-DD');
      }
      return null;
    },
    dateTime_isoString: function (date) {
      if (moment(date).isValid()) {
        return moment(date).toISOString();
      }
      return null;
    },
    dateTime_time: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('HH:mm:ss');
      }
      return null;
    },
    dateTime_timeMilliseconds: function (date) {
      if (moment(date).isValid()) {
        return moment(date).format('HH:mm:ss.SSS');
      }
      return null;
    },
    dateTime_IANA_Timezones: function () {
      return moment.tz.names();
    },
    dateTime_Convert_IANA_Timezone: function (timeZone) {
      return moment().tz(timeZone).format('z');
    },
    toMoment (date) {
      if (moment(date).isValid()) {
        return moment(date)
      }
    },
    // gets used when calling moment with () because otherwise build will throw error (is not a function)
    moment () {
      return moment();
    }
  }
}